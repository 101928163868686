import { MissingTranslationHandler } from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    // handle(params: MissingTranslationHandlerParams) {
    //     return 'some value';
    // }

    handle(): string {
        return '';
    }
}
