import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ProductServiceProductGetAllRequestUIModel,
    ProductServiceProductGetAllResponseUIModel,
    ProductServiceEcommerceProductGetByNosRequestUIModel,
    ProductServiceProductGetComponentsRequestUIModel,
    ProductServiceProductGetComponentsResponseUIModel,
    ProductServiceProductWebRepository,
    ProductUIModel,
    ProductServiceEcommerceProductGetByNosResponseUIModel,
    ProductServiceEcommerceProductGetWeightByNoUIModel,
    ProductServiceWestfaliaProductRepository,
    ProductServiceWestfaliaProductGetAllRequestUIModel,
    ProductServiceWestfaliaProductGetAllResponseUIModel,
    ErrorUIModel,
    WestfaliaProductUIModel,
} from '@socialonce/enganchesaragon-admin-lib';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastsService } from '../toasts/toast.service';

@Injectable({
    providedIn: 'root',
})
export class ProductsService {
    constructor(
        private productRepository: ProductServiceProductWebRepository,
        private westfaliaProductRepository: ProductServiceWestfaliaProductRepository,
        private toastsService: ToastsService,
        private translateService: TranslateService
    ) {}

    getAll(
        request: ProductServiceProductGetAllRequestUIModel
    ): Observable<ProductServiceProductGetAllResponseUIModel> {
        return this.productRepository.getAll(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Product'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    get(id: number): Observable<ProductUIModel> {
        return this.productRepository.getSingle(id).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Product'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getComponents(
        request: ProductServiceProductGetComponentsRequestUIModel
    ): Observable<ProductServiceProductGetComponentsResponseUIModel> {
        return this.productRepository.getProductComponents(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.ProductComponents'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getByNos(
        request: ProductServiceEcommerceProductGetByNosRequestUIModel
    ): Observable<ProductServiceEcommerceProductGetByNosResponseUIModel[]> {
        return this.productRepository.getProductsByNos(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Product'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getWeight(request: ProductServiceEcommerceProductGetWeightByNoUIModel): Observable<number> {
        return this.productRepository.getWeight(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Product'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getAllWesfaliaProducts(
        request: ProductServiceWestfaliaProductGetAllRequestUIModel
    ): Observable<ProductServiceWestfaliaProductGetAllResponseUIModel> {
        return this.westfaliaProductRepository.getAll(request).pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Product'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getSingleWestfaliaProduct(id: number): Observable<WestfaliaProductUIModel> {
        return this.westfaliaProductRepository.getSingle(id).pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Product'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }
}
