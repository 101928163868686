<h2 class="text-xl-bold">{{ 'Errors.ExpiredSession' | translate }}</h2>
<p>{{ 'Errors.ExpiredSessionMessage' | translate }}</p>
<div>
    <ea-basic-button
        [style]="'form-light'"
        [text]="'Actions.LogIn' | translate"
        (sendAction)="renewToken()"></ea-basic-button>
    <ea-basic-button
        [style]="'form-light'"
        [text]="'Actions.LogOut' | translate"
        (sendAction)="logout()"></ea-basic-button>
</div>
