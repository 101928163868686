import { UserAuthorisationApi } from '../models/auth-api.model';
import { UserAuthorisationUI } from '../models/auth-ui.model';

export class AuthAdapter {
    convertGetResponseToUI(request: UserAuthorisationApi): UserAuthorisationUI {
        const convertedRequest: UserAuthorisationUI = {
            roles: request.roles,
            permissions: request.permissions.reduce((obj, item) => {
                return { ...obj, [item]: true };
            }, {}),
            employeeId: request.employeeId,
        };

        return convertedRequest;
    }
}
