import { Injectable } from '@angular/core';
import {
    EcommerceServiceUrlsGetHrefLangsRequestUIModel,
    EcommerceServiceUrlsGetHrefLangsResponseUIModel,
    EcommerceServiceUrlsGetSingleRequestUIModel,
    EcommerceServiceUrlsRepository,
} from '@socialonce/enganchesaragon-admin-lib';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UrlsService {
    constructor(private urlsRepository: EcommerceServiceUrlsRepository) {}

    getSingle(request: EcommerceServiceUrlsGetSingleRequestUIModel): Observable<string> {
        return this.urlsRepository.getSingle(request);
    }

    getHrefLangs(
        request: EcommerceServiceUrlsGetHrefLangsRequestUIModel
    ): Observable<EcommerceServiceUrlsGetHrefLangsResponseUIModel> {
        return this.urlsRepository.getHrefLangs(request).pipe(
            catchError(err => {
                return of({
                    data: [],
                    totalRows: 0,
                });
            })
        );
    }
}
