import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthHeaderForUnprotectedEndpointsWhenLoggedInInterceptor implements HttpInterceptor {
    constructor(private msal: MsalService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.msal
            .acquireTokenSilent({
                forceRefresh: false,
                scopes: [
                    'https://engaragonb2c.onmicrosoft.com/microservices-api/user_impersonation',
                ],
            })
            .pipe(
                switchMap(token => {
                    const noAuthHeader = req.headers.get('No-Auth');
                    if ((noAuthHeader && noAuthHeader === 'true') || req.url.endsWith('.json')) {
                        return next.handle(req);
                    }
                    const headers = req.headers.set('Authorization', 'Bearer ' + token.accessToken);
                    const modifiedRequest = req.clone({ headers });
                    return next.handle(modifiedRequest);
                }),
                catchError(err => {
                    if (!err.status) {
                        // If the user is not logged in, we return the same request
                        // We compare with the field 'status' (from ErrorUIModel)
                        // If the object returned does not contain that field, then it's an error from B2C, and we should
                        // retry request with no headers
                        // PS: I'm sure there's a better way to do this
                        return next.handle(req);
                    }

                    return throwError(err);
                })
            );
    }
}
