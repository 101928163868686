import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    LanguagesLibService,
    ProductServiceLanguageGetAllRequestUIModel,
} from '@socialonce/enganchesaragon-admin-lib';
import { Observable } from 'rxjs';
import { LanguagesService } from 'src/app/ecommerce/shared/services/languages/languages.service';

@Injectable({
    providedIn: 'root',
})
export class EaLanguageGuard implements CanActivate {
    constructor(
        private languagesService: LanguagesService,
        private router: Router,
        private translateService: TranslateService,
        private languagesLibService: LanguagesLibService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise(resolve => {
            const languageCodeIso = route.paramMap.get('languageCode');
            const request: ProductServiceLanguageGetAllRequestUIModel = {};

            this.languagesService.getAll(request).subscribe(
                data => {
                    this.languagesService.languages$.next(data.data);

                    if (data.data.find(l => l.codeIso === languageCodeIso)) {
                        if (languageCodeIso) {
                            this.languagesLibService.languageCodeIsoSelected.next(languageCodeIso);
                            this.languagesService.languageCodeIsoSelected$.next(languageCodeIso);
                            this.translateService.use(languageCodeIso);
                        }

                        resolve(true);
                    } else {
                        const userLocaleLanguageCodeIso = navigator.language.split('-')[0];

                        // TODO: Check if this is necessary.
                        // (If defaultLang is already "es", this check may not be necessary as it would be set to "es"
                        // if it doesn't exist)
                        if (data.data.find(l => l.codeIso === userLocaleLanguageCodeIso)) {
                            this.languagesLibService.languageCodeIsoSelected.next(
                                userLocaleLanguageCodeIso
                            );
                            this.languagesService.languageCodeIsoSelected$.next(
                                userLocaleLanguageCodeIso
                            );
                            // this.translateService.setDefaultLang(userLocaleLanguageCodeIso);
                            this.translateService.use(userLocaleLanguageCodeIso);
                            void this.router.navigate([userLocaleLanguageCodeIso]);

                            resolve(true);
                        } else {
                            this.languagesLibService.languageCodeIsoSelected.next('es');
                            this.languagesService.languageCodeIsoSelected$.next('es');
                            // this.translateService.setDefaultLang('es');
                            this.translateService.use('es');
                            void this.router.navigate(['es']);

                            resolve(true);
                        }
                    }
                },
                () => {
                    this.languagesLibService.languageCodeIsoSelected.next('es');
                    this.languagesService.languageCodeIsoSelected$.next('es');
                    // this.translateService.setDefaultLang('es');
                    this.translateService.use('es');
                    void this.router.navigate(['es']);

                    resolve(true);
                }
            );
        });
    }
}
