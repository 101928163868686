import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EaLanguageGuard } from './shared/auth/guards/ea-language.guard';
import { EaLoginGuard } from './shared/auth/guards/ea-login.guard';
import { EaRoutesGuard } from './shared/auth/guards/ea-routes.guard';
import { AppComponent } from './app.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [EaLoginGuard],
    },
    {
        path: 'auth-redirect',
        loadChildren: () =>
            import('./auth-redirect/auth-redirect.module').then(m => m.AuthRedirectModule),
        canActivate: [EaLoginGuard],
    },
    {
        path: ':languageCode',
        loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule),
        canActivate: [EaLanguageGuard],
        canLoad: [EaRoutesGuard],
    },
    {
        path: '**',
        pathMatch: 'prefix',
        component: AppComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
