import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { ApiAuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class EaLoginGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private msalGuard: MsalGuard,
        private authService: ApiAuthService,
        private msalService: MsalService
    ) {}

    checkAndSetActiveAccount(): void {
        const activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            const accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    // First Call
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkRoute(route, state);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkRoute(childRoute, state);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const ars: ActivatedRouteSnapshot = new ActivatedRouteSnapshot();
        ars.url = segments;
        const state: RouterStateSnapshot = { root: ars, url: segments.join('/') };
        return this.checkRoute(ars, state);
    }

    private checkRoute(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (route.fragment?.includes('AADB2C90118')) {
            this.authService.redirectToResetPassword();
            return of(false);
        }
        return this.msalGuard.canActivate(route, state).pipe(
            switchMap(() => {
                this.checkAndSetActiveAccount();
                return of(true);
            })
        );
    }
}
