import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { LanguagesService } from '../languages/languages.service';
import { ToastsService } from '../toasts/toast.service';

@Injectable({
    providedIn: 'root',
})
export class RoutesService {
    languageCodeIso = 'es';
    fileNames = [
        'areas',
        'client-area',
        'compliance-reports-old',
        'hitches',
        'kits',
        'official-services',
        'register',
        'search',
        'shopping-cart',
        'cookies',
    ];

    constructor(
        private languagesService: LanguagesService,
        private toastsService: ToastsService,
        private translateService: TranslateService
    ) {
        languagesService.languageCodeIsoSelected$.subscribe(data => (this.languageCodeIso = data));
    }

    getPathName(
        fileName:
            | 'areas'
            | 'client-area'
            | 'compliance-reports'
            | 'compliance-reports-old'
            | 'hitches'
            | 'kits'
            | 'official-services'
            | 'register'
            | 'search'
            | 'shopping-cart'
            | 'cookies',
        targetComponent: string
    ): Promise<string | undefined> {
        return new Promise((resolve, reject) => {
            import(`../../../../../assets/routes/${this.languageCodeIso}/routes-${fileName}`)
                .then(data => {
                    const routes = data.routesLanguages as Routes;
                    const route = routes.find(
                        r => r.data?.targetComponent.name === targetComponent
                    );
                    if (route) resolve(route.path);
                    else resolve(routes.find(r => r.component?.name === targetComponent)?.path);
                })
                .catch(err => {
                    this.toastsService.show({
                        time: 5000,
                        msg: this.translateService.instant('Errors.Urls'),
                        type: 'error',
                    });
                    reject(err);
                    return throwError(err);
                });
        });
    }

    getTargetComponentName(
        fileName:
            | 'areas'
            | 'client-area'
            | 'compliance-reports'
            | 'hitches'
            | 'official-services'
            | 'register'
            | 'search'
            | 'shopping-cart'
            | 'cookies',
        path: string
    ): Promise<string | undefined> {
        return new Promise((resolve, reject) => {
            import(`../../../../../assets/routes/${this.languageCodeIso}/routes-${fileName}`)
                .then(data => {
                    const routes = data.routesLanguages as Routes;
                    const route = routes.find(r => r.path === path);
                    if (route) resolve(route.data?.targetComponent.name);
                })
                .catch(err => {
                    this.toastsService.show({
                        time: 5000,
                        msg: this.translateService.instant('Errors.Urls'),
                        type: 'error',
                    });
                    reject(err);
                    return throwError(err);
                });
        });
    }

    getRoutesFile(
        fileName:
            | 'areas'
            | 'client-area'
            | 'compliance-reports'
            | 'compliance-reports-old'
            | 'hitches'
            | 'kits'
            | 'official-services'
            | 'register'
            | 'search'
            | 'shopping-cart'
            | 'cookies',
        language?: string
    ): Promise<Routes | undefined> {
        return new Promise(resolve => {
            const languageCodeIso = language ? language : this.languageCodeIso;
            void import(`../../../../../assets/routes/${languageCodeIso}/routes-${fileName}`).then(
                data => {
                    resolve(data.routesLanguages as Routes);
                }
            );
        });
    }
}
