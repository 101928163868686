import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    init(): Promise<boolean> {
        // App initializer
        return new Promise(resolve => {
            resolve(true);
        });
    }
}
