import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class CustomTranslateLoader implements TranslateLoader {
    constructor(private httpClient: HttpClient) {}

    getTranslation(lang: string): Observable<unknown> {
        return this.httpClient.get(
            `${environment.assets.blobStorage}/public-websites/ecommerce/i18n/${lang}.json`
        );
    }
}
