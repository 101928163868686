import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiAuthService } from './shared/auth/auth.service';
import { ShoppingCartsService } from './ecommerce/shared/services/shopping-carts/shopping-carts.service';
import {
    EcommerceServiceShoppingCartLinesCreateRequestUIModel,
    LanguagesLibService,
} from '@socialonce/enganchesaragon-admin-lib';
import { ProductsService } from './ecommerce/shared/services/products/products.service';
import { switchMap } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { AccountInfo } from '@azure/msal-browser';
import { UrlsService } from './ecommerce/shared/services/urls/urls.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { CustomCookieService } from './ecommerce/shared/services/cookies/custom-cookie.service';
import { RoutesService } from './ecommerce/shared/services/routes/routes.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    accountInfo: AccountInfo | null = null;
    loading = true;

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private authService: ApiAuthService,
        private shoppingCartsService: ShoppingCartsService,
        private languagesLibService: LanguagesLibService,
        private productsService: ProductsService,
        private gtmService: GoogleTagManagerService,
        private urlsService: UrlsService,
        private cookieConsentService: NgcCookieConsentService,
        private cookieService: CookieService,
        private customCookieService: CustomCookieService,
        private routesService: RoutesService
    ) {
        this.addGoogleMapsUrl();
        // void this.gtmService.addGtmToDom();
        // this.getUserRoles();
        this.setShoppingCartProducts();

        // const userLocaleLanguageCodeIso = navigator.language.split('-')[0];
        const userLocaleLanguageCodeIso = 'es';
        translateService.setDefaultLang('es');
        translateService.use(userLocaleLanguageCodeIso);
        languagesLibService.languageCodeIsoSelected.next(userLocaleLanguageCodeIso);

        if (window.location.pathname === '/') {
            location.href = `/${userLocaleLanguageCodeIso}`;
            // void this.router.navigateByUrl(`/${userLocaleLanguageCodeIso}`);
        }

        // Change HTML lang attribute when language changes:
        this.translateService.onLangChange
            .pipe(
                switchMap(newLang => {
                    document.querySelector('html')?.setAttribute('lang', newLang.lang);
                    return this.translateService.get([
                        'Cookies.Banner.Allow',
                        'Cookies.Banner.Deny',
                        'Cookies.Banner.Header',
                        'Cookies.Banner.Link',
                        'Cookies.Banner.Message.1',
                        'Cookies.Banner.Message.2',
                        'Cookies.Banner.Message.3',
                        'Cookies.Banner.Message.4',
                        'Cookies.Banner.Message.5',
                        'Cookies.Banner.Message.6.1',
                        'Cookies.Banner.Message.6.2',
                        'Cookies.Banner.Message.6.3',
                        'Cookies.Banner.Policy',
                    ]);
                })
            )
            .subscribe(data => {
                const config = this.cookieConsentService.getConfig();
                void this.routesService
                    .getPathName('areas', 'CookiesPolicyComponent')
                    .then(resCookies => {
                        void this.routesService
                            .getPathName('cookies', 'CookiesConfigurationComponent')
                            .then(resConfiguration => {
                                if (config) {
                                    if (config.elements) {
                                        config.elements.allow = `<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">${
                                            data['Cookies.Banner.Allow'] as string
                                        }</a>`;
                                        config.elements.deny = `<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">${
                                            data['Cookies.Banner.Deny'] as string
                                        }</a>`;
                                        config.elements.header = data['Cookies.Banner.Header'];
                                        const urlSegments = this.router.url.split('/').slice(1);
                                        const configurationHref = `${
                                            urlSegments[0] !== ''
                                                ? urlSegments[0]
                                                : userLocaleLanguageCodeIso
                                        }/${resCookies as string}/${resConfiguration as string}`;
                                        const policyHref = `${
                                            urlSegments[0] !== ''
                                                ? urlSegments[0]
                                                : userLocaleLanguageCodeIso
                                        }/${resCookies as string}`;
                                        config.elements.link = `<a style="text-decoration: none" aria-label="configurar cookies" tabindex="0" class="cc-btn cc-link" href="${configurationHref}">${
                                            data['Cookies.Banner.Link'] as string
                                        }</a>`;
                                        config.elements.message = `
                                <p style="text-align: justify;">${
                                    data['Cookies.Banner.Message.1'] as string
                                }</p>\
                                <br />\
                                <p style="text-align: justify;">${
                                    data['Cookies.Banner.Message.2'] as string
                                }</p>\
                                <br />\
                                <p style="text-align: justify;">${
                                    data['Cookies.Banner.Message.3'] as string
                                }</p>\
                                <br />\
                                <p style="text-align: justify;">${
                                    data['Cookies.Banner.Message.4'] as string
                                }</p>\
                                <br />\
                                <p style="text-align: justify;">${
                                    data['Cookies.Banner.Message.5'] as string
                                }</p>\
                                <br />\
                                <p style="text-align: justify;">${
                                    data['Cookies.Banner.Message.6.1'] as string
                                } <b><a href="${policyHref}" style="text-decoration: none">${
                                            data['Cookies.Banner.Message.6.2'] as string
                                        }</a></b> ${
                                            data['Cookies.Banner.Message.6.3'] as string
                                        }</p>
                                `;
                                    }
                                    if (config.content) {
                                        config.content.policy = data['Cookies.Banner.Policy'];
                                    }
                                }

                                this.cookieConsentService.destroy();
                                this.cookieConsentService.init(config);
                            });
                    });
            });

        this.cookieConsentService.statusChange$.subscribe(status => {
            if (status) {
                if (status.status === 'allow') {
                    this.customCookieService.setGlobalPreferences(true);
                } else if (status.status === 'deny') {
                    this.customCookieService.setGlobalPreferences(false);
                    const cookiesList = this.cookieService.getAll();
                    for (const [key, value] of Object.entries(cookiesList)) {
                        if (key !== 'cookieconsent_status') {
                            this.cookieService.delete(
                                key,
                                '/',
                                `${environment.assets.ecommerce.url}`
                            );
                        }
                    }
                }
                const consentCookie = this.cookieService.get('cookieconsent_status');
                this.customCookieService.showHideCookieContainer(!!consentCookie);
            }
        });

        this.cookieConsentService.revokeChoice$.subscribe(() => {
            this.customCookieService.showHideCookieContainer(false);
        });

        void this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                setTimeout(() => {
                    void this.routesService
                        .getPathName('areas', 'CookiesPolicyComponent')
                        .then(resCookies => {
                            void this.routesService
                                .getPathName('cookies', 'CookiesConfigurationComponent')
                                .then(resConfiguration => {
                                    const configUrl = `${resCookies as string}/${
                                        resConfiguration as string
                                    }`;
                                    const cookiePreferences = JSON.parse(
                                        localStorage.getItem('cookies_preferences') || '{}'
                                        // this.cookieService.get('cookieconsent_status') || '{}'
                                    );
                                    const consentCookie =
                                        this.cookieService.get('cookieconsent_status');

                                    this.customCookieService.checkPreferences(
                                        cookiePreferences as { [key: string]: string }
                                    );

                                    this.customCookieService.showHideCookieContainer(
                                        !!consentCookie
                                    );

                                    // if (!consentCookie && !this.router.url.includes(configUrl)) {
                                    //     // this.cookieConsentService.fadeIn();
                                    //     this.customCookieService.showHideCookieContainer(
                                    //         !!consentCookie
                                    //     );
                                    // } else if (!this.router.url.includes(configUrl)) {
                                    //     // this.cookieConsentService.close(true);
                                    //     this.customCookieService.showHideCookieContainer(
                                    //         !!consentCookie
                                    //     );
                                    // } else {
                                    //     // this.cookieConsentService.close(false);
                                    //     this.customCookieService.showHideCookieContainer(
                                    //         !!consentCookie
                                    //     );
                                    // }

                                    if (cookiePreferences['analysis']) {
                                        let gtmTag = {};

                                        if (this.authService.isLoggedIn() && this.accountInfo) {
                                            gtmTag = {
                                                event: 'login',
                                                pageName: this.accountInfo.localAccountId,
                                            };
                                        }
                                        void this.gtmService.pushTag(gtmTag);
                                    }

                                    // Delete previous hreflangs
                                    const previousHeadLinks =
                                        document.head.getElementsByTagName('link');
                                    if (previousHeadLinks) {
                                        const previousHeadLinksArray: Element[] =
                                            Array.prototype.slice.call(previousHeadLinks);

                                        if (
                                            previousHeadLinksArray &&
                                            previousHeadLinksArray.length
                                        ) {
                                            const filteredHeadLinks = previousHeadLinksArray.filter(
                                                link => {
                                                    const attribute = link.getAttribute('rel');
                                                    return attribute && attribute === 'alternate';
                                                }
                                            );

                                            for (const headLink of filteredHeadLinks) {
                                                document.head.removeChild(headLink);
                                            }
                                        }
                                    }

                                    // Set hreflangs
                                    this.urlsService
                                        .getHrefLangs({ url: item.url })
                                        .subscribe(response => {
                                            response.data.forEach(urlData => {
                                                const link = document.createElement('link');
                                                link.rel = 'alternate';
                                                link.hreflang = urlData.codeIso;
                                                link.href = urlData.url;
                                                document.head.appendChild(link);
                                            });
                                        });
                                });
                        });
                }, 250);
            }
        });

        authService.userData$.subscribe(data => {
            this.accountInfo = data;
        });
    }

    // getUserRoles(): void {
    //     this.authService.checkRoles();
    // }

    addGoogleMapsUrl(): void {
        const script = document.createElement(`script`);
        script.setAttribute(
            'src',
            `https://maps.googleapis.com/maps/api/js?key=${environment.assets.googleMaps.key}`
        );
        document.head.appendChild(script);
    }

    setShoppingCartProducts(): void {
        this.authService.getRoles().subscribe(() => {
            const productsLocalStorage = localStorage.getItem('products');
            let products: EcommerceServiceShoppingCartLinesCreateRequestUIModel[] = [];
            if (productsLocalStorage) products = JSON.parse(productsLocalStorage);
            if (this.authService.isLoggedIn()) {
                products.forEach((product, index) => {
                    this.productsService
                        .getWeight({ no: product.product.no })
                        .pipe(
                            switchMap((weight: number) => {
                                product.product.weight = weight;
                                return this.shoppingCartsService.createLine(product);
                            })
                        )
                        .subscribe(() => {
                            if (index === products.length - 1) {
                                localStorage.removeItem('products');
                                this.getShoppingCartItems();
                            }
                        });
                });
                this.getShoppingCartItems();
            } else {
                this.shoppingCartsService.counterShoppingCart$.next(products.length);
            }
        });
    }

    getShoppingCartItems(): void {
        this.shoppingCartsService.getItemsCount().subscribe(numberOfItems => {
            this.shoppingCartsService.counterShoppingCart$.next(numberOfItems);
        });
    }
}
