import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Toast } from '../../models/toast.model';

@Injectable({
    providedIn: 'root',
})
export class ToastsService {
    toasts$ = new ReplaySubject<Toast>();

    show(toast: Toast): void {
        this.toasts$.next(toast);

        setTimeout(() => {
            this.toasts$.next(undefined);
        }, toast.time);
    }
}
