import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { RoutesService } from '../routes/routes.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomCookieService {
    cookieService = inject(CookieService);
    cookieConsentService = inject(NgcCookieConsentService);
    router = inject(Router);
    routesService = inject(RoutesService);

    setGlobalPreferences(status: boolean): void {
        localStorage.setItem(
            'cookies_preferences',
            JSON.stringify({
                analysis: status,
                functionality: status,
                advertising: status,
                advertising2: status,
            })
        );
    }

    checkPreferences(preferences: { [key: string]: string }): void {
        const cookiesList = this.cookieService.getAll();
        for (const [key, value] of Object.entries(cookiesList)) {
            if (key !== 'cookieconsent_status') {
                if (!preferences['analysis']) {
                    if (
                        key.includes('_ga') ||
                        key.includes('CLID') ||
                        key.includes('_gid') ||
                        key.includes('_gat') ||
                        key.includes('SM') ||
                        key.includes('MR') ||
                        key.includes('_clck') ||
                        key.includes('_clsk') ||
                        key.includes('DV') ||
                        key.includes('OTZ') ||
                        key.includes('AEC') ||
                        key.includes('OSID')
                    ) {
                        this.cookieService.delete(key, '/', `${environment.assets.ecommerce.url}`);
                    }
                }

                if (!preferences['functionality']) {
                    if (
                        key.includes('NID') ||
                        key.includes('OGPC') ||
                        key.includes('PREF') ||
                        key.includes('ENID')
                    ) {
                        this.cookieService.delete(key, '/');
                    }
                }

                if (!preferences['advertising']) {
                    if (
                        key.includes('YSC') ||
                        key.includes('VISITOR') ||
                        key.includes('MUID') ||
                        key.includes('ANONCHK') ||
                        key.includes('yt-') ||
                        key.includes('3PAPISID') ||
                        key.includes('SRM_B')
                    ) {
                        this.cookieService.delete(key, '/');
                    }
                }

                if (!preferences['advertising2']) {
                    if (
                        key.includes('PAPISID') ||
                        key.includes('SAPISID') ||
                        key.includes('APISID') ||
                        key.includes('1PAPISID') ||
                        key.includes('1P_JAR')
                    ) {
                        this.cookieService.delete(key, '/');
                    }
                }
            }
        }
    }

    showHideCookieContainer(hasConsentCookie: boolean): void {
        void this.routesService.getPathName('areas', 'CookiesPolicyComponent').then(resCookies => {
            void this.routesService
                .getPathName('cookies', 'CookiesConfigurationComponent')
                .then(resConfiguration => {
                    const configUrl = `${resCookies as string}/${resConfiguration as string}`;
                    const isConfigPage = this.router.url.includes(configUrl);
                    const cookieContainer = document.getElementById('cookie-container');
                    const bannerElement = document
                        .getElementsByClassName('cc-banner')
                        .item(0) as HTMLElement;
                    const toggleBannerElement = document
                        .getElementsByClassName('cc-revoke')
                        .item(0) as HTMLElement;
                    if ((hasConsentCookie || isConfigPage) && cookieContainer) {
                        cookieContainer.style.minHeight = '0%';
                        bannerElement.style.display = 'none';
                        toggleBannerElement.style.display = 'block';
                        // this.cookieConsentService.close(true);
                    } else if (!hasConsentCookie && cookieContainer && !isConfigPage) {
                        cookieContainer.style.minHeight = '100%';
                        bannerElement.style.display = 'block';
                        toggleBannerElement.style.display = 'block';
                        // this.cookieConsentService.open();
                    }
                });
        });
    }
}
