<a *ngIf="routerLink" [routerLink]="routerLink ? routerLink : []" [queryParams]="queryParams">
    <ng-container [ngTemplateOutlet]="button"></ng-container>
</a>

<a *ngIf="!routerLink">
    <ng-container [ngTemplateOutlet]="button"></ng-container>
</a>

<ng-template #button>
    <button
        (click)="doAction(); $event.stopPropagation()"
        [disabled]="disabled"
        [ngClass]="
            style === 'search'
                ? 'color-white text-lg-medium background-orange-500 action-search'
                : style === 'search-main'
                ? 'color-white text-lg-medium background-orange-600 action-search-main-hitches'
                : style === 'search-main-hitches'
                ? 'color-white text-lg-medium background-orange-600 action-search-main-hitches'
                : style === 'searchbar'
                ? 'color-white text-lg-medium background-orange-500 action-searchbar'
                : style === 'card-buy'
                ? 'color-gray-25 text-base-semibold background-orange-500 action-card-buy'
                : style === 'card-buy-product'
                ? 'card box-shadow-0-2-4-10 color-gray-25 text-base-semibold background-orange-500 action-card-buy'
                : style === 'counter'
                ? 'color-gray-600 text-xl-bold action-counter background-gray-25'
                : style === 'zoom-google-maps'
                ? 'color-gray-600 text-xl-bold action-zoom-google-maps background-gray-25 box-shadow-0-2-4-10'
                : style === 'add-to-cart'
                ? 'color-gray-50 text-sm-bold action-cart background-orange-400 box-shadow-0-4-4-25'
                : style === 'buy-now'
                ? 'color-gray-50 text-sm-bold action-buy-now background-orange-600 box-shadow-0-4-4-25'
                : style === 'view-workshops'
                ? 'color-gray-50 text-sm-bold action-view-workshops background-orange-500 box-shadow-0-4-4-25'
                : style === 'keep-buying'
                ? 'color-gray-900 text-sm-semibold action-keep-buying'
                : style === 'complete-order'
                ? 'color-gray-50 text-sm-semibold action-complete-order background-orange-500'
                : style === 'complete-order-dark'
                ? 'color-gray-50 text-base-semibold action-complete-order-dark background-orange-600'
                : style === 'inform'
                ? 'color-gray-25 text-base-medium action-inform background-gray-700'
                : style === 'form-light'
                ? 'color-white text-lg-medium background-orange-500 action-form-light'
                : style === 'form-light-small'
                ? 'color-white text-base-medium background-orange-500 action-form-light-small'
                : style === 'form-light-long'
                ? 'color-white text-lg-medium background-orange-500 action-form-light-long'
                : style === 'long'
                ? 'color-white text-base-semibold background-orange-500 action-long'
                : style === 'filter-outline'
                ? 'color-orange-500 text-base-semibold action-filter-outline'
                : style === 'register-home'
                ? 'color-gray-25 text-lg-semibold background-orange-600 action-register-home'
                : ''
        "
        class="button-base">
        <img *ngIf="withImage && withImage === 'left'" [src]="image" />
        <span class="font-family-saira" *ngIf="!withImage || withImage !== 'center'">{{
            text
        }}</span>
        <img *ngIf="withImage && withImage === 'right'" [src]="image" />
        <img *ngIf="withImage && withImage === 'center'" [src]="image" />
    </button>
</ng-template>
