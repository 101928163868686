import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    LanguageUIModel,
    ProductServiceLanguageGetAllRequestUIModel,
    ProductServiceLanguageGetAllResponseUIModel,
    ProductServiceLanguageRepository,
} from '@socialonce/enganchesaragon-admin-lib';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastsService } from '../toasts/toast.service';

@Injectable({
    providedIn: 'root',
})
export class LanguagesService {
    languages$ = new ReplaySubject<LanguageUIModel[]>();
    languageCodeIsoSelected$ = new BehaviorSubject<string>(this.translateService.getDefaultLang());

    constructor(
        private languageRepository: ProductServiceLanguageRepository,
        private toastsService: ToastsService,
        private translateService: TranslateService
    ) {}

    getAll(
        request: ProductServiceLanguageGetAllRequestUIModel
    ): Observable<ProductServiceLanguageGetAllResponseUIModel> {
        return this.languageRepository.getAll(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.Languages'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }
}
