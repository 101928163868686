import { Component } from '@angular/core';
import { ApiAuthService } from '../../../app/shared/auth/auth.service';

@Component({
    selector: 'ea-basic-login-expired-dialog',
    templateUrl: './basic-login-expired-dialog.component.html',
    styleUrls: ['./basic-login-expired-dialog.component.scss'],
})
export class BasicLoginExpiredDialogComponent {
    constructor(private authService: ApiAuthService) {}

    renewToken(): void {
        this.authService.logout(true);
    }

    logout(): void {
        this.authService.logout();
    }
}
