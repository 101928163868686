import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserPermissionsUI, UserRoleUI } from './models/auth-ui.model';
import { UserAuthorisationApi } from './models/auth-api.model';
import { AuthAdapter } from './adapters/auth.adapter';
import { AccountInfo } from '@azure/msal-common';
import { CustomersService } from 'src/app/ecommerce/shared/services/customers/customers.service';
import { CustomerLiteSharedUIModel } from '@socialonce/enganchesaragon-admin-lib';

@Injectable({
    providedIn: 'root',
})
export class ApiAuthService {
    authAdapter = new AuthAdapter();
    private userRoles = new BehaviorSubject<UserRoleUI[] | null>(null);
    perms: UserPermissionsUI | null = null;
    employeeId: string | null = null;
    userData$ = new ReplaySubject<AccountInfo | null>();
    customerData$ = new ReplaySubject<CustomerLiteSharedUIModel>();
    isLoadingCustomerRoles = false;
    hasErrorsOnLoadingCustomerRoles = false;

    // userRol$ = new ReplaySubject<string>();

    constructor(
        private msalService: MsalService,
        private http: HttpClient,
        private customersService: CustomersService
    ) {
        //Handle Msal redirect
        // this.msalService.handleRedirectObservable().subscribe({
        //     next: result => {},
        //     error: error => {},
        // });
    }

    login(): void {
        this.cleanUserAuths();
        this.msalService.loginRedirect({ scopes: ['user.read', 'openid', 'profile'] });
    }

    redirectToResetPassword(): void {
        window.location.href = `${environment.assets.b2c.resetPasswordUrl}&client_id=7d679aaf-57bb-4aee-a166-f44fb59ceb23&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.enganchesaragon.com%2Fauth&scope=openid&response_type=id_token&prompt=login`;
        // window.location.href = `${environment.assets.b2c.resetPasswordTestUrl}&client_id=7d679aaf-57bb-4aee-a166-f44fb59ceb23&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.enganchesaragon.com%2Fauth&scope=openid&response_type=id_token&prompt=login`;
    }

    logout(redirectToLogin = false): void {
        this.cleanUserAuths();

        if (redirectToLogin) this.msalService.logout({ postLogoutRedirectUri: '/auth-redirect' });
        else this.msalService.logout();
    }

    getRoles(): Observable<UserRoleUI[] | null> {
        this.userData$.next(this.msalService.instance.getActiveAccount());
        const isLoggedIn = this.isLoggedIn();
        if (isLoggedIn) {
            if (
                !this.userRoles.getValue() &&
                (!this.isLoadingCustomerRoles || this.hasErrorsOnLoadingCustomerRoles)
            ) {
                this.isLoadingCustomerRoles = true;
                return this.loadUserRoles().pipe(
                    switchMap(() => {
                        return this.getCustomer().pipe(
                            tap(customer => {
                                this.customerData$.next(customer);
                            })
                        );
                    }),
                    catchError(err => {
                        this.hasErrorsOnLoadingCustomerRoles = true;
                        this.isLoadingCustomerRoles = false;
                        return throwError(err);
                    }),
                    switchMap(() => this.userRoles.asObservable())
                );
            }
        } else {
            this.cleanUserAuths();
        }

        return this.userRoles.asObservable();
    }

    getCustomer(): Observable<CustomerLiteSharedUIModel> {
        return this.customersService.getFullCustomer();
    }

    getPerms(): UserPermissionsUI {
        return { ...this.perms };
    }

    getCustomerId(): string | null {
        return this.employeeId;
    }

    isLoggedIn(): boolean {
        return this.msalService.instance.getAllAccounts().length > 0;
        // return !!this.msalService.instance.getActiveAccount();
    }

    private loadUserRoles(): Observable<UserRoleUI[] | null> {
        return this.http
            .get<UserAuthorisationApi>(
                `${environment.endpoints.microservices.user}/Permissions`,
                {}
            )
            .pipe(
                map(authorisationObj => this.authAdapter.convertGetResponseToUI(authorisationObj)),
                switchMap(authorisationObj => {
                    this.userRoles.next(authorisationObj.roles);
                    this.perms = authorisationObj.permissions;
                    this.employeeId = authorisationObj.employeeId;
                    this.isLoadingCustomerRoles = false;
                    this.hasErrorsOnLoadingCustomerRoles = false;
                    return this.userRoles;
                }),
                catchError(() => {
                    this.logout();
                    this.hasErrorsOnLoadingCustomerRoles = true;
                    this.isLoadingCustomerRoles = false;
                    return of(null);
                })
            );
    }

    private cleanUserAuths() {
        this.userRoles.next(null);
        this.perms = null;
        this.employeeId = null;
    }
}
