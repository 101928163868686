import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ea-basic-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input()
    text = '';

    @Input()
    style = 'search';

    @Input()
    disabled = false;

    @Input()
    withImage?: 'left' | 'right' | 'center';

    @Input()
    image?: string;

    @Input()
    routerLink?: string;

    @Input()
    queryParams?: { [key: string]: unknown };

    @Output()
    sendAction = new EventEmitter<boolean>();

    doAction(): void {
        this.sendAction.emit(true);
    }
}
